<template>
  <div>
    <vs-row class="mt-4 mb-0">
      <vs-col vs-w="12">
        <ul class="activity-timeline">
          <li v-for="(takeover, key) in reverseTakeovers" :key="key">
            <div class="timeline-icon">
              <feather-icon
                :icon="takeover._finishedAt ? 'CheckIcon' : 'PlayIcon'"
                :class="{
                  'bg-success': takeover._finishedAt,
                  'bg-primary': !takeover._f
                }"
                svgClasses="text-white stroke-current w-5 h-5"
              ></feather-icon>
            </div>
            <div class="timeline-info vx-row">
              <p
                class="font-semibold"
                v-if="takeover.user && takeover.user.name"
              >
                <feather-icon
                  icon="UserIcon"
                  svgClasses="w-5 h-5"
                ></feather-icon>
                {{ takeover.user.name }}
              </p>
            </div>
            <div class="timeline-info vx-row">
              <div
                class="mt-3"
                v-if="takeover.tags && takeover.tags.length > 0"
              >
                <feather-icon
                  class="float-left pr-1"
                  icon="TagIcon"
                  svgClasses="w-5 h-5"
                ></feather-icon>
                <br />
                <vs-chip v-for="(tag, key) in takeover.tags" :key="key">{{
                  tag
                }}</vs-chip>
              </div>
            </div>
            <div class="timeline-info vx-row mt-3" v-if="takeover.comment">
              <p>
                <feather-icon
                  class="float-leftt pr-1"
                  icon="MessageCircleIcon"
                  svgClasses="w-5 h-5"
                ></feather-icon>
              </p>
              <p>{{ takeover.comment }}</p>
            </div>
            <div v-if="takeover._finishedAt" class="vx-row mt-3">
              <small class="text-grey activity-e-time">
                {{lang.attentionRequests.takeover.ended[languageSelected]+': '}}
                {{ takeover._finishedAt | moment('from', 'now') }}
              </small>,&nbsp;
              <small class="text-grey activity-e-time">
                {{lang.attentionRequests.takeover.duration[languageSelected]+': '}}
                {{
                  ((new Date(takeover._finishedAt).getTime() -
                    new Date(takeover._createdAt).getTime()) /
                    1)
                    | duration('humanize')
                }}
              </small>
            </div>
            <div v-else class="vx-row mt-3">
              <small class="text-grey activity-e-time">
                {{lang.attentionRequests.takeover.started[languageSelected]+': '}}
                {{ takeover._createdAt | moment('from', 'now') }}
              </small>
            </div>
          </li>
        </ul>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'UserData',
    props: {
      takeovers: {
        type: Array
      }
    },
    computed: {
      ...mapState(['languageSelected', 'lang']),
      reverseTakeovers() {
        return JSON.parse(JSON.stringify(this.takeovers)).reverse()
      }
    }
  }
</script>
